<template>
    <div class="bg-white box-shadow border-r p-30">
        <h4>UID : {{ $store.state.userId }}</h4>
        <h4>{{ $t('account.email') }} : {{ $store.state.userEmail }}</h4>
        <h4>KYC {{ $t('account.verify') }} : <el-tag effect="plain" hit :type="verifyState[$store.state.userVerified][0]">{{ verifyState[$store.state.userVerified][1] }}</el-tag></h4>
    </div>
</template>

<script>
export default {
    data() {
        return {
            verifyState: [
                ['info', this.$t('account.verify_state.unsubmitted')],
                ['danger', this.$t('account.verify_state.fail')],
                ['primary', this.$t('account.verify_state.checking')],
                ['success', this.$t('account.verify_state.success')]
            ]
        }
    },
    created: function() {
        this.fatchData()
    },
    methods: {
        fatchData: async function () {
            // const { data } = await this.$request.get('user/info')
            const { data } = await this.$request.post('v1/user/info')
            this.$store.commit('userEmail', data.email)
            this.$store.commit('userId', data.id)
            this.$store.commit('userVerified', data.verified)
        }
    }
}
</script>
<style lang="scss" scoped>
//
</style>